/* Weather Close */
setTimeout(() => {
    document.addEventListener(
        "click",
        function(event) {
            if (
                event.target.matches(".button-close-modal") ||
                !event.target.closest("#div3")
            ) {
                closeModal()
            }
        },
        false
    )


    function closeModal() {
        var div3 = document.querySelector("#div3");
        if (div3) {
            document.querySelector("#div3").style.display = "none"

        }
    }
}, 2000);


/* Date */
// setTimeout(() => {

//     var d = new Date,
//         month = new Array;
//     month[0] = "يناير", month[1] = "فبراير", month[2] = "مارس", month[3] = "أبريل",
//         month[4] = "مايو", month[5] = "يونيه", month[6] = "يوليو", month[7] = "أغسطس", month[8] = "سبتمبر", month[9] = "أكتوبر",
//         month[10] = "نوفمبر", month[11] = "ديسمبر";
//     var month_name = month[d.getMonth()],
//         day_of_month = d.getDate(),
//         current_year = d.getFullYear(),
//         dayOfMonthElement = document.getElementById("current_day"),
//         currentMonthElement = document.getElementById("current_month"),
//         currentYearElement = document.getElementById("current_year");
//     ! function() {
//         currentMonthElement.innerHTML = month_name, dayOfMonthElement.innerHTML = day_of_month,
//             currentYearElement.innerHTML = current_year
//     }();
// }, 2000);

/*  Time */
// setTimeout(() => {

//     function clock() {
//         var hours = document.getElementById("hours");
//         var minutes = document.getElementById("minutes");
//         var seconds = document.getElementById("seconds");
//         var phase = document.getElementById("phase");

//         var h = new Date().getHours();
//         var m = new Date().getMinutes();
//         var s = new Date().getSeconds();
//         var am = "ص";

//         if (h > 12) {
//             h = h - 12;
//             var am = "م";
//         }

//         h = h < 10 ? "0" + h : h;
//         m = m < 10 ? "0" + m : m;
//         s = s < 10 ? "0" + s : s;

//         hours.innerHTML = h;
//         minutes.innerHTML = m;
//         seconds.innerHTML = s;
//         phase.innerHTML = am;
//     }
//     var interval = setInterval(clock, 1000);

// }, 3000);


setTimeout(() => {

    /** mousemove **/
    (function(root, factory) {
        if (typeof define === 'function' && define.amd) {
            define(['exports'], factory);
        } else if (typeof exports !== 'undefined') {
            factory(exports);
        } else {
            factory((root.dragscroll = {}));
        }
    }(this, function(exports) {
        var _window = window;
        var _document = document;
        var mousemove = 'mousemove';
        var mouseup = 'mouseup';
        var mousedown = 'mousedown';
        var EventListener = 'EventListener';
        var addEventListener = 'add' + EventListener;
        var removeEventListener = 'remove' + EventListener;
        var newScrollX, newScrollY;

        var dragged = [];
        var reset = function(i, el) {
            for (i = 0; i < dragged.length;) {
                el = dragged[i++];
                el = el.container || el;
                el[removeEventListener](mousedown, el.md, 0);
                _window[removeEventListener](mouseup, el.mu, 0);
                _window[removeEventListener](mousemove, el.mm, 0);
            }

            // cloning into array since HTMLCollection is updated dynamically
            dragged = [].slice.call(_document.getElementsByClassName('dragscroll'));
            for (i = 0; i < dragged.length;) {
                (function(el, lastClientX, lastClientY, pushed, scroller, cont) {
                    (cont = el.container || el)[addEventListener](
                        mousedown,
                        cont.md = function(e) {
                            if (!el.hasAttribute('nochilddrag') ||
                                _document.elementFromPoint(
                                    e.pageX, e.pageY
                                ) == cont
                            ) {
                                pushed = 1;
                                lastClientX = e.clientX;
                                lastClientY = e.clientY;

                                e.preventDefault();
                            }
                        }, 0
                    );

                    _window[addEventListener](
                        mouseup, cont.mu = function() { pushed = 0; }, 0
                    );

                    _window[addEventListener](
                        mousemove,
                        cont.mm = function(e) {
                            if (pushed) {
                                (scroller = el.scroller || el).scrollLeft -=
                                    newScrollX = (-lastClientX + (lastClientX = e.clientX));
                                scroller.scrollTop -=
                                    newScrollY = (-lastClientY + (lastClientY = e.clientY));
                                if (el == _document.body) {
                                    (scroller = _document.documentElement).scrollLeft -= newScrollX;
                                    scroller.scrollTop -= newScrollY;
                                }
                            }
                        }, 0
                    );
                })(dragged[i++]);
            }
        }


        if (_document.readyState == 'complete') {
            reset();
        } else {
            _window[addEventListener]('load', reset, 0);
        }

        exports.reset = reset;
    }));

    /****/
}, 2000);

setTimeout(() => {
    const element = document.getElementById("kkk");
    if (element) {
        element.addEventListener("click", openNaved);

    }

    function openNaved() {
        var mySidenav = document.getElementById("mySidenav");
        if (mySidenav) {
            document.getElementById("mySidenav").style.width = "360px";

        }
    }
}, 1000);

setTimeout(() => {
    const element = document.getElementById("closebtn");
    if (element) {

        element.addEventListener("click", openNaved2);
    }

    function openNaved2() {
        var mySidenav = document.getElementById("mySidenav");
        if (mySidenav) {
            document.getElementById("mySidenav").style.width = "0px";

        }
    }
}, 1000);

/** **/
